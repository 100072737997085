import React from 'react'
import { useEffect } from 'react'
import Layout from '../../../components/Layout'
import Seo from '../../../components/Seo'
import i18n from '../../../locales/he.yaml'
import { fireEvent } from '../../../analytics'

const ChallengeThanksTemplate = () => {
  useEffect(() => {
    fireEvent('Lead')
  }, [])
  return (
    <div className='hero has-navbar-fixed-top'>
      <div className='hero-body'>
        <div className='container content'>
          <p>{i18n.challengeThanks.text1}</p>
          <h2>{i18n.challengeThanks.header}</h2>
          <p>{i18n.challengeThanks.text2}</p>
          <p className='is-flex justify-center'>
            <a
              href={i18n.challengeThanks.callToActionLink}
              className='button is-nude is-flex items-center justify-start'
              target='_blank'
              rel='noreferrer'
            >
              {i18n.challengeThanks.callToAction}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

const ChallengeThanks = ({ location: { pathname } }) => {
  return (
    <Layout>
      <Seo
        metadata={i18n.challengeThanks.metadata}
        pathname={pathname}
        robots='noindex, nofollow'
      />
      <ChallengeThanksTemplate />
    </Layout>
  )
}

export default ChallengeThanks
